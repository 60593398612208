import {Box, Button} from "grommet";

const rootStyle = {
    fontSize: "32px",
    paddingTop: "8px",
    paddingBottom: "8px",
    textAlign: "center",
    border: "1px solid white",
    borderRadius: "25px",
    marginBottom: "16px",
    width: "80%",
    transform: "translate(13%)"
}
const ctaStyle = {
    fontSize: "24px",
    height: "48px",
    marginTop: "16px",
    fontStyle: "Strong",
}
const subTextStyle = {
    fontSize: "12px",
    marginTop: "16px",
    marginBottom: "8px",
    display: "inline"
}

export const ConversionBlock = props => {
    return (
        <Box style={rootStyle} direction={"column"} background={"white"} id={"contact"}>
            <Box style={{padding: "16px"}}>
                <b>Having Car Problems?</b>
                    <Button style={ctaStyle} primary href={"tel:2012670607"}>
                        <Box style={{transform: "translateY(22%)"}}>
                            Call R&T
                        </Box>
                    </Button>
                <Box style={subTextStyle}>
                    Text 201-450-8565 or <a href={"mailto: lachhman.anthony@gmail.com"}>Email</a> instead
                </Box>
            </Box>
        </Box>
    )
}
