import './App.css';
import React from 'react';
import {TopNavBar} from "./components/header";
import {topNavOptions} from "./config/topNavOptions";
import {Gallery} from "./components/gallery";
import {carouselOptions} from "./config/carouselOptions";
import {ConversionBlock} from "./components/conversionBlock";
import {InfoBlock} from "./components/infoBlock";
import {CommentBlock} from "./components/commentBlock";
import {Map} from "./components/map";
import {Box, Main} from "grommet";

const App = props => {
    return (
        <Main>
            <TopNavBar options={topNavOptions}/>
            <Box direction={"column"} background={"#e7e7e7"}>
                <Gallery options={carouselOptions}/>
                <ConversionBlock/>
                <InfoBlock options={carouselOptions}/>
                <CommentBlock/>
                <Map/>
            </Box>
        </Main>
    )
}

export default App;
