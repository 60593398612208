import {Button, Header, Nav} from "grommet";
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const TopNavBar = props => {
    const options = props.options;
    return (
        <Header background="#7D4CDB" pad="small">
            <Button primary color={"#7D4CDB"} href ={"/"} hoverIndicator label={options.branding} style={{borderRadius: "5px"}}/>
            <Nav direction="row">
                <NavChoices choices={options.choices}/>
            </Nav>
        </Header>
    )
}

const NavChoices = props => {
    return props.choices.map((x) =>
        <Button as={AnchorLink} href={x.hashRoute} primary color={"white"} label={x.name} style={{marginRight:"16px", borderRadius: "5px"}}/>
    )
}
