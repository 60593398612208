import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {Box, Image} from "grommet";

const style = {
    border: "1px",
    marginTop: "16px",
    display: "block",
    width: "100%",
    maxHeight: "40%",
    // objectFit: "cover",
}

const bannerStyle = {
    display: "block",
    textAlign: "center",
    width: "80%",
    margin: "auto",
    background: "#7D4CDB",
    fontSize: "16px",
    lineHeight: "1",
    padding: "16px 32px",
    position: "relative",
    color: "white",
    textOverflow: "wrap",
    transform: "translateY(-50%)"
}

export const Gallery = props => {
    const options = props.options;
    return (
        // <Box height="medium" width="large" overflow={"auto"} style={style}>
        //     <Carousel fill>
        //         {CarouselImages(options.images)}
        //     </Carousel>
        // </Box>
        <Box>
            <Image fit={"cover"} src={require("../assets/" + options.images[0].file).default}
                   alt={options.images[0].alt}/>
            <div style={bannerStyle}>
                Over 40 Years of Mechanic Experience
            </div>
        </Box>
    );
}

const CarouselImages = images => {
    return images.map(x =>
        <Image fit={"cover"} src={require("../assets/" + x.file).default} alt={x.alt}/>
    )
}
