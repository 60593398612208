export const topNavOptions = {
    branding: "R&T Auto",
    choices: [
        {
            name: "About",
            hashRoute: "#about",
        },
        {
            name: "Contact",
            hashRoute: "#contact",
        }
    ]
}
