import {useState} from "react";
import {Box, Button, TextInput} from "grommet";

const env = "http://localhost:3001"

const sampleRequestData = {
    //Names should have validation keeping them below 8 characters, so as not to break styling
    comments: [
        {
            name: "Anthony",
            comment: "Incredible service"
        },
        {
            name: "Sandy",
            comment: "Amazing staff"
        },
        {
            name: "David",
            comment: "Awesome mechanics"
        }
    ]
}

const baseStyle = {
    width: "80%",
    transform: "translate(13%)",
    marginTop: "16px",
    padding: "8px",
    marginBottom: "12px"
}

const commentStyle = {
    padding: "8px",
    border: "1px solid white",
    borderRadius: "25px",
    background: "white"
}

const commentUserNameStyle = {
    padding: "8px",
    border: "1px solid white",
    borderRadius: "25px",
    background: "white",
    transform: "translateY(40%) translatex(-20%)",
    width: "50%"
}

export const CommentBlock = props => {
    const [value, setValue] = useState('');
    /**
     * The following commented out code is an hook that calls an API request to retrieve comment data.
     * Uncomment it when you're ready to have dynamic comments on the site.
     */

        // const [comments, setComments] = useState([]);
        //
        // const getComments = async () => {
        //     setComments( await fetch(env + "/comments")
        //         .then(response => response.json())
        //         .then(json => json.comments))
        // }
        //
        // useEffect(() => {
        //     getComments().then(x => x)
        //     console.log(comments)
        // },[])

    const Comment = props => {
            const data = props.data
            return (
                <Box>
                    <Box style={commentUserNameStyle}>
                        {data.name}
                    </Box>
                    <Box style={commentStyle}>
                        {"\"" + data.comment + "\""}
                    </Box>
                </Box>
            )
        }

    return (
        <Box style={baseStyle}>
            <Box style={{fontWeight: "bold", fontSize: "18px"}}>
                Reviews
            </Box>
            <div className={"divider"}/>
            <Box direction={"column"} align={"center"}>
                {sampleRequestData.comments.map(x => <Box><Comment data={x}/></Box>)}
            </Box>
            <Box style={{fontWeight: "bold", fontSize: "18px", marginTop: "12px"}}>
                Leave a Review
            </Box>
            <Box direction={"row"}>
                <TextInput
                    placeholder="Type Here"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                />
                <Button primary style={{padding: "12px", borderRadius:"0px"}}>
                    Submit
                </Button>
            </Box>
        </Box>
    )
}
