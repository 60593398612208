import {Box, Image} from "grommet";
import image from "../assets/4.png";

const rootStyle = {
    width: "80%",
    transform: "translate(13%)",
    marginTop: "8px"
}

const imageStyle = {
    minHeight: "300px",
    minWidth: "200px",
    maxHeight: "600px",
    maxWidth: "500px",
    border: "1px solid white",
    borderRadius: "25px"
}

const infoStyling = {
    border: "1px solid white",
    borderRadius: "25px",
    margin: "auto",
    transform: "translate(-50%)",
    minWidth: "75px",
    padding: "8px",
    fontWeight: "bold"
}

export const InfoBlock = (props) => {
    return (
        <Box style={rootStyle} direction={"row"} justify={"center"} id={"about"}>
            <Image src={image} alt={"4"} style={imageStyle}/>
            <Box direction={"column"} justify={"center"}>
                <Box background={"white"} style={infoStyling}>
                    Reliable
                </Box>
                <Box background={"white"} style={infoStyling}>
                    Timely
                </Box>
                <Box background={"white"} style={infoStyling}>
                    Friendly
                </Box>
            </Box>
        </Box>
    )
}
