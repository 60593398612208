export const carouselOptions = {
    images: [
        {
            file: "1.png",
            alt: "1",
            legend: "Test 1"
        },
        {
            file: "2.png",
            alt: "2",
            legend: "Test 2"
        },
        {
            file: "3.png",
            alt: "3",
            legend: "Test 3"
        },
        {
            file: "4.png",
            alt: "4",
            legend: "Test 4"
        }
    ]
}
