import React from "react";
import {Box} from "grommet";

const baseStyle = {
    width: "80%",
    transform: "translate(13%)",
    marginBottom: "16px"
}

const iframeStyle = {
    height: "300px",
    border: "none",
    borderRadius: "25px",
    marginTop: "8px"
}

export const Map = () => {
    return <Box style={baseStyle}>
        <Box style={{fontWeight: "bold", fontSize: "18px", marginTop: "12px"}}>
            Location
        </Box>
        <div className={"divider"}/>
        <iframe src="https://snazzymaps.com/embed/359083" style={iframeStyle}/>
    </Box>
}
